import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import fusee from '../assets/images/Game_Fusee.svg';
import Debriefing from '../components/Game/Debriefing';
import Connected from '../components/Game/Connected';
import CodeGame from '../components/Game/CodeGame';
import Fields from '../components/Game/Fields';
import StepBar from '../components/Game/StepBar';
import SvgTotem from '../components/Game/Totem/SvgTotem';

import RootStore from '../RootStore';
import style from './Game.scss';
import LoadingButton from '../components/Loading/LoadingButton';

function Game() {
  const { PartieManager, UserManager } = useContext(RootStore);

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const room = useParams().id;

  useEffect(() => {
    PartieManager.numQuestion = 0;
  }, []);
  useEffect(() => {
    async function fetchData() {
      if (UserManager.user === null) {
        navigate('/login');
      } else {
        if (room != null) {
          const id = parseInt(room.charAt(0), 10) || 0;
          const create = location.state?.create;
          PartieManager.initPartie(room, id, UserManager.user, create);
          if (id === 0 || Number.isNaN(id)) navigate(-1);
        } else {
          navigate(-1);
        }
        PartieManager.connected
          .then(() => {
            setLoading(false);
          })
          .catch((error) => {
            navigate('/', { state: { error: error.message } });
          });
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (PartieManager.partieSave !== undefined) {
      PartieManager.socket.disconnect();
      navigate(`/historique/${PartieManager.partieSave}`);
    }
  }, [PartieManager.partieSave]);

  if (loading) {
    return null;
  }
  if (PartieManager.numQuestion >= 10) {
    const onSubmit = () => {
      PartieManager.sendPartie();
    };

    return (
      <Debriefing
        parties={PartieManager.partie}
        PartieManager={PartieManager}
            // eslint-disable-next-line react/jsx-props-no-spreading

      >
        {PartieManager.isCoach() && (
        <LoadingButton
          type="button"
          onClick={onSubmit}
          className={style.sendResult}
        >
          Enregister la partie
        </LoadingButton>
        )}
      </Debriefing>
    );
  }

  return (
    <Row className={`${style.Game} justify-content-center`}>
      <Col xs={11} lg={9} className={`${style.Interface} mt-auto text-end`}>
        {PartieManager.isCoach() === true ? <CodeGame code={room} /> : null}
      </Col>

      <Col xs={11} lg={9} className={`${style.Interface}`}>
        <StepBar />

        <Row className="mt-5">
          <Connected
            otherUser={PartieManager.otherUser}
            className="mt-auto text-end"
          />

          <div className={style.form}>
            <div className={style.formQuestion}>
              <p className="question">
                {
                  PartieManager.currentQuestion.question
                }
              </p>
              <img src={fusee} alt="fusée" />
            </div>
            <fieldset disabled={!PartieManager.partie.user} className={style.formMain}>
              <Fields />
              <fieldset
                id="Totem"
                className={style.imgTotem}
                disabled={
                  !PartieManager.currentQuestion.ressources?.includes('C')
                }
              >
                <SvgTotem />
              </fieldset>
              {/* <img className={style.imgTotem} src={totem} alt="fuse" /> */}
            </fieldset>
          </div>
        </Row>
      </Col>
    </Row>
  );
}

export default observer(Game);
