import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function PopUp({ affichage, handleClose, onValid }) {
  const handleValid = () => {
    onValid();
    handleClose();
  };
  return (
    <Modal show={affichage} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Êtes-vous sûr de vouloir passer cette utilisateur en coach</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Non
        </Button>
        <Button variant="primary" onClick={handleValid}>
          Oui
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PopUp;
