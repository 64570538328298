import React, { useState, useContext } from 'react';
import { observer } from 'mobx-react';
import ReactTagInput from '@pathofdev/react-tag-input';
import { Button, Modal } from 'react-bootstrap';

import RootStore from '../../../RootStore';

import style from './Adjectif.scss';

import imgRessources from '../../../assets/images/Game_Ressources.svg';
import imgBesoins from '../../../assets/images/Game_Besoin.svg';
import imgAffect from '../../../assets/images/Game_Affect.svg';
import Ressources from '../Ressources';

function Adjectif({ ressources }) {
  const { PartieManager } = useContext(RootStore);
  const { listTags } = PartieManager.currentReponse;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showBesoins, setShowBesoins] = useState(false);
  const handleCloseBesoins = () => setShowBesoins(false);
  const handleShowBesoins = () => setShowBesoins(true);

  const [showAffects, setShowAffects] = useState(false);
  const handleCloseAffects = () => setShowAffects(false);
  const handleShowAffects = () => setShowAffects(true);

  const onChange = (newTags) => {
    if (!listTags.includes([...newTags].pop())
      || listTags.length > newTags.length) {
      PartieManager.setListTags(newTags);
    }
  };

  return (

    <div className={style.Adjectif}>

      <div className={style.inputTags}>
        <ReactTagInput
          tags={listTags}
          removeOnBackspace
          placeholder={listTags.length === 0 ? 'Ecrire et presser entrer' : ' '}
          onChange={onChange}
          maxTags={15}
        />
      </div>
      <div className={style.buttonTag}>
        <p>Aides :</p>
        {ressources.includes('A') && (
        <Button variant="primary" onClick={handleShowAffects}>
          <img src={imgAffect} alt="Affect" />
          <p>Affect</p>
        </Button>
        )}
        {ressources.includes('B') && (
        <Button variant="primary" onClick={handleShowBesoins}>
          <img src={imgBesoins} alt="Besoins" />
          <p>Besoins</p>
        </Button>
        )}
        {ressources.includes('R') && (
        <Button variant="primary" onClick={handleShow}>
          <img src={imgRessources} alt="Ressources" />
          <p>Ressources</p>
        </Button>
        )}
        <Modal className={style.aideZone} size="lg" show={show} onHide={handleClose} contentClassName={style.modalContent}>
          <Modal.Header closeButton className={style.modalHeader}>
            <img src={imgRessources} alt="Ressources" />
            <Modal.Title>  Ressources</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Ressources indexRessource="ressources" />
          </Modal.Body>
        </Modal>
        <Modal className={style.aideZone} size="lg" show={showBesoins} onHide={handleCloseBesoins} contentClassName={style.modalContent}>
          <Modal.Header closeButton className={style.modalHeader}>
            <img src={imgBesoins} alt="Besoins" />
            <Modal.Title>  Besoins</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Ressources indexRessource="besoins" />
          </Modal.Body>
        </Modal>
        <Modal className={style.aideZone} size="lg" show={showAffects} onHide={handleCloseAffects} contentClassName={style.modalContent}>
          <Modal.Header closeButton className={style.modalHeader}>
            <img src={imgAffect} alt="Affect" />
            <Modal.Title>  Affect</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Ressources indexRessource="affects" />
          </Modal.Body>
        </Modal>
      </div>
    </div>

  );
}
export default observer(Adjectif);
