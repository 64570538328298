import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Button, Image, Row,
} from 'react-bootstrap';

import style from './Cards.scss';

function Cards({
  image, title, texte, onclick, isSelected,
}) {
  const styleImage = {
    backgroundImage: `url(${image})`,
  };

  return (
    <div className={`${style.cards}  ${isSelected ? style.cardsSelected : ''}`}>
      <div className={style.image} style={styleImage} />
      <h4>{title}</h4>
      <p>{texte}</p>
      <Button onClick={onclick}>Choisir</Button>
    </div>
  );
}

Cards.propTypes = {
  isSelected: PropTypes.bool,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  texte: PropTypes.string.isRequired,
  onclick: PropTypes.func,
};

Cards.defaultProps = {
  isSelected: false,
};

export default Cards;
