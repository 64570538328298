import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Radio } from 'antd';
import { Col, Row } from 'react-bootstrap';
import SearchTab from '../../components/List/SearchTab';
import Back from '../../components/Home/Back';
import style from './HistoriqueCoach.scss';
import Loading from '../../components/Loading/Loading';
import CustomPagination from '../../components/List/Pagination';

function HistoriqueCoach() {
  const navigate = useNavigate();

  const columns = {
    Date: { trie: ['date'], size: 7 },
    Utilisateur: { trie: ['user'], size: 12 },
    'Type de partie': { trie: ['type'], size: 10 },
    Objectif: { trie: ['objectif'] },
    Debriefing: { trie: ['debriefing'] },
  };
  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalItems, setTotalItems] = useState(10);
  const [typeHisto, setTypeHisto] = useState('coach');

  const totalPages = Math.ceil(totalItems / limit);
  if (totalPages < page && totalPages > 0) {
    setPage(totalPages);
  }

  useEffect(() => {
    let url;
    switch (typeHisto) {
      case 'coach':
        url = `${APP_ENV.API_EP_URI}/coach/history`;
        break;
      case 'user':
        url = `${APP_ENV.API_EP_URI}/user/history`;
        break;
    }
    setData(null);
    axios.post(url)
      .then((response) => {
        const wdata = response.data.map(
          ({
            id,
            date,
            type,
            user: { firstName, lastName },
            debriefing,
            objectif,
          }) => ({
            id,
            datePartie: new Date(date),
            user: `${firstName} ${lastName}`,
            type,
            objectif,
            debriefing,
          }),
        );
        setData(wdata);
      });
  }, [typeHisto]);
  const onclickRow = (elt) => {
    navigate(`/historique/${elt}`, { state: { back: { link: '/coach/historique', text: 'Historique' } } });
  };

  return (
    <div className={style.HistoCoach}>
      <Back link="/" texte="Accueil" />
      {data
        ? (
          <>
            <Row className="justify-content-center" id="histoCoach">
              <h1 className="text-center">
                Liste des parties
                {' '}
                {typeHisto === 'coach' ? 'coachées' : 'jouées'}
              </h1>
              <Radio.Group
                value={typeHisto}
                onChange={(event) => setTypeHisto(event.target.value)}
                buttonStyle="solid"
                className="mb-2"
                style={{ paddingLeft: '10.7rem' }}
              >
                <Radio.Button value="coach">Coachées</Radio.Button>
                <Radio.Button value="user">Jouées</Radio.Button>
              </Radio.Group>
              <Col xs={10}>
                <SearchTab
                  col={columns}
                  data={data}
                  keyOptions={{ key: 'id', displayKey: false, onclickRow }}
                  pagination={{ limit, page, onTotal: (total) => setTotalItems(total) }}
                  size={100}
                />
              </Col>
            </Row>
            <Row className="justify-content-center mt-3">
              <Col xs={10}>
                <CustomPagination
                  currentPage={page}
                  totalPages={totalPages}
                  onPageChange={(newPage) => setPage(newPage)}
                />
              </Col>
            </Row>
          </>
        ) : (
          <Loading />
        )}
    </div>
  );
}
export default HistoriqueCoach;
