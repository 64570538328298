import React, { useContext } from 'react';
import {
  Container, Nav, Navbar, Image,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import RootStore from '../../RootStore';
import Logo from '../../assets/images/logo-wys-app.png';

function NavbarHome() {
  const { UserManager } = useContext(RootStore);
  const onClickDeconnexion = () => {
    UserManager.deconnexion();
  };

  return (
    <Navbar expand="lg">
      <Container className="justify-content-center">
        <Navbar.Brand style={{ color: 'white' }} href="/">
          <Image src={Logo} width={150} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ backgroundColor: 'white' }} />
        <Navbar.Collapse id="basic-navbar-nav" className="pt-3 flex-grow-0">
          <Nav className="justify-content-center">
            <Nav.Link as={Link} style={{ color: 'white' }} to="/profile">
              Profil
            </Nav.Link>
            {UserManager.accessControl('ROLE_COACH') === true
              ? (
                <Nav.Link as={Link} style={{ color: 'white' }} to="/coach/historique">
                  Historique
                </Nav.Link>
              ) : (
                <Nav.Link as={Link} style={{ color: 'white' }} to="/historique">
                  Historique
                </Nav.Link>
              )}

            {UserManager.accessControl('ROLE_COACH') === true
              ? (
                <Nav.Link as={Link} style={{ color: 'white' }} to="/user">
                  Liste utilisateurs
                </Nav.Link>
              ) : null}
            <Nav.Link ass={Link} style={{ color: 'white' }} to="/" onClick={onClickDeconnexion}>
              Déconnection
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarHome;
