import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import style from './SvgTotem.scss';
import RootStore from '../../../RootStore';

function SelectElement({
  id, transform, onClick = () => {}, children,
}) {
  const { PartieManager } = useContext(RootStore);

  return (
    <g
      id={id}
      transform={transform}
      onClick={() => { onClick(id); }}
      className={PartieManager.currentReponse?.listTotem?.includes(id) ? style.select : null}
    >
      {children}
    </g>
  );
}
export default observer(SelectElement);
