import React, { useState } from 'react';
import {
  Button,
} from 'react-bootstrap';
import style from './CodeGame.scss';

function CodeGame({ code }) {
  const [copier, setCopier] = useState(false);
  const handleClick = () => {
    const textToCopy = code;
    navigator.clipboard.writeText(textToCopy);
    setCopier(true);
    setTimeout(() => setCopier(false), 1500);
  };
  let texte = `Code : ${code}`;
  if (copier === true) { texte = 'Code copié !'; }
  return (
    <Button onClick={handleClick} className={`${style.CodeButton}`}>
      {texte}
    </Button>
  );
}

export default CodeGame;
