import { makeAutoObservable } from 'mobx';
import axios from '../axiosConfig';

class AxiosError {
  _errors;

  _loading = false;

  constructor() {
    makeAutoObservable(this);

    axios.interceptors.request.use(
      (config) => {
        this.errors = undefined;
        this.loading = true;
        return config;
      },
    );
    axios.interceptors.response.use(
      (response) => {
        this.errors = undefined;
        this.loading = false;
        return response;
      },
      (error) => {
        if (error.response) {
          const wError = { code: error.response.status };
          if (error.response.data.error) {
            wError.message = error.response.data.error;
            if (error.response.data.code) {
              wError.code = error.response.data.code;
            }
          } else {
            wError.message = error.response.data.detail || error.response.data;
          }
          this.errors = wError;
        } else if (error.request) {
          this.errors = { code: 500, message: 'Le serveur ne répond pas' };
        } else {
          this.errors = { code: error.code, message: error.message };
        }
        this.loading = false;
        return Promise.reject(error);
      },
    );
  }

  get errors() {
    return this._errors;
  }

  set errors(value) {
    if (value) console.log(value);
    this._errors = value;
  }

  get loading() {
    return this._loading;
  }

  set loading(value) {
    this._loading = value;
  }

// addError(error) {
  //   console.log(error);
  //   this.errors.push(error);
  // }
  //
  // clearErrors() {
  //   this.errors = [];
  // }
}

export default AxiosError;
