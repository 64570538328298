import { action, makeAutoObservable, runInAction } from 'mobx';
import axios from '../axiosConfig';
import User from './User';

class UserManager {
  _user = null;

  _dataLoading = false;

  constructor() {
    makeAutoObservable(this, {
      _dataLoading: false,
      _profileLoad: false,
    });
    this.loadUser();
    if (sessionStorage.getItem('user')) {
      const data = JSON.parse(sessionStorage.getItem('user'));
      this._updateUser(data);
    }
  }

  get user() {
    return this._user;
  }

  loadUser() {
    return new Promise((resolve, reject) => {
      this.getProfile().then((response) => {
        const { data } = response;
        this._updateUser(data);
        resolve();
      }).catch(action(() => {
        this._user = null;
        reject();
      }));
    });
  }

  _updateUser(user) {
    runInAction(() => {
      this._user = new User(
        user.id,
        user.lastName,
        user.firstName,
        user.email,
        user.roles,
        user.token,
      );
      sessionStorage.setItem('user', JSON.stringify(user));
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getProfile() {
    return new Promise((resolve, reject) => {
      // if (this._dataLoading) {
      // reject();
      // } else {
      //   this._dataLoading = true;
      const url = `${APP_ENV.API_EP_URI}/user/profile`;

      axios.get(url).then((response) => {
        // this._dataLoading = false;
        resolve(response);
      }).catch((err) => {
        // this._dataLoading = false;
        reject(err);
      });
      // }
    });
  }

  isConnected() {
    return !!this.user;
  }

  accessControl(role = null) {
    if (role === null) {
      return this.user !== null;
    }
    return this.user?.roles.includes(role);
  }

  connexion(email, password) {
    return new Promise((resolve, reject) => {
      if (this._dataLoading) {
        reject();
      } else {
        const url = `${APP_ENV.API_EP_URI}/login`;
        this._dataLoading = true;

        // const paramRequest = { username: email, password };

        axios.post(url, {
          email,
          password,
        }).then((response) => {
          const { data: { user } } = response;
          this._updateUser(user);
          this._dataLoading = false;
          resolve(response);
        }).catch((err) => {
          this._dataLoading = false;
          reject(err);
        });
      }
    });
  }

  deconnexion() {
    axios.get(`${APP_ENV.API_EP_URI}/logout`);
    sessionStorage.removeItem('user');
    this._user = undefined;
  }

  inscription(email, plainPassword, firstName, lastName) {
    return new Promise((resolve, reject) => {
      if (this._dataLoading) {
        reject();
      } else {
        const url = `${APP_ENV.API_EP_URI}/register`;
        this._dataLoading = true;

        axios.post(url, {
          email,
          plainPassword,
          lastName,
          firstName,
        }).then((response) => {
          this._dataLoading = false;
          resolve(response);
        }).catch((err) => {
          this._dataLoading = false;
          reject(err);
        });
      }
    });
  }
}

export default UserManager;
