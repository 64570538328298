import React, { useState, useContext } from 'react';
import {
  Col,
  Row,
  Form,
  Button,
  Container,
} from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Back from '../components/Home/Back';
import ErrorForm from '../components/Form/ErrorForm';
import RootStore from '../RootStore';

import style from './Login.scss';
import LoadingButton from '../components/Loading/LoadingButton';

function Login() {
  const { UserManager } = useContext(RootStore);
  const navigate = useNavigate();
  const location = useLocation();

  const [formValue, setformValue] = useState({
    username: '',
    password: '',
  });

  const redirect = location.state?.redirect;

  const onsubmit = (e) => {
    e.preventDefault();
    UserManager.connexion(formValue.username, formValue.password).then((response) => {
      if (response.status === 200) {
        if (redirect) {
          navigate(redirect);
        } else {
          navigate(-1);
        }
      }
    });
  };

  const handleChange = (e) => {
    setformValue({
      ...formValue,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Container fluid className={` ${style.Login} d-flex flex-column`}>
      <Back link="/" texte="Accueil" />
      <Row className="justify-content-center w-100  h-100">
        <Col xs="4" className="text-center h-auto my-auto mx-auto ">
          <ErrorForm />
          <Col className={`text-center h-auto my-auto mx-auto ${style.From}`}>
            <Form onSubmit={onsubmit}>
              <div className={style.field}>
                <h2>Connection</h2>
                <Form.Group controlId="formLogin">
                  <Form.Control type="text" placeholder="Email" name="username" value={formValue.username} onChange={handleChange} />
                </Form.Group>
                <Form.Group controlId="formPassword">
                  <Form.Control type="password" placeholder="Mot de passe" name="password" value={formValue.password} onChange={handleChange} />
                </Form.Group>
              </div>
              <LoadingButton className={style.click} variant="primary" type="submit">
                Se connecter
              </LoadingButton>
            </Form>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
