/* eslint-disable object-curly-newline */
import React, { useState, useContext } from 'react';
// import axios from 'axios';
import { Col, Row, Form, Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Back from '../components/Home/Back';
import style from './Registration.scss';
import RootStore from '../RootStore';
import ErrorForm from '../components/Form/ErrorForm';
import LoadingButton from '../components/Loading/LoadingButton';

function Registration() {
  const navigate = useNavigate();
  const { UserManager } = useContext(RootStore);

  const [formValue, setformValue] = useState({
    username: '',
    plainPassword: '',
    lastName: '',
    firstName: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Par défaut, on initialise la valeur avec la saisie de l'utilisateur
    let capitalizedValue = value;

    // Si la propriété du formulaire est nom ou prénom, on met la première lettre en majuscule
    if (name === 'firstName' || name === 'lastName') {
      capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
    }

    // Mettre à jour l'état avec la nouvelle valeur transformée
    setformValue({
      ...formValue,
      [name]: capitalizedValue,
    });
  };

  const onsubmit = (e) => {
    e.preventDefault();

    UserManager.inscription(
      formValue.username,
      formValue.plainPassword,
      formValue.lastName,
      formValue.firstName,
    ).then(() => {
      navigate('/login', { state: { redirect: '/' } });
    });
  };
  return (
    <Container fluid className={` ${style.Login} d-flex flex-column`}>
      <Back link="/" texte="Accueil" />
      <Row className="justify-content-center w-100  h-100">
        <Col xs="4" className="text-center h-auto my-auto mx-auto" style={{ minWidth: '20em' }}>
          <ErrorForm />
          <Col className={`text-center h-auto my-auto mx-auto ${style.From}`}>
            <Form onSubmit={onsubmit}>
              <div className={style.field}>
                <h2>Inscription</h2>
                <Form.Group controlId="formNom">
                  <Form.Control
                    type="text"
                    placeholder="Nom"
                    name="lastName"
                    required="required"
                    value={formValue.lastName}
                    onChange={handleChange}
                    maxLength="255"
                  />
                </Form.Group>
                <Form.Group controlId="formPrenom">
                  <Form.Control
                    type="text"
                    placeholder="Prenom"
                    name="firstName"
                    required="required"
                    value={formValue.firstName}
                    onChange={handleChange}
                    maxLength="255"
                  />
                </Form.Group>

                <Form.Group controlId="formLogin">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    name="username"
                    required="required"
                    value={formValue.username}
                    onChange={handleChange}
                    maxLength="255"
                  />
                </Form.Group>

                <Form.Group controlId="formPassword">
                  <Form.Control
                    type="password"
                    placeholder="Mot de passe"
                    name="plainPassword"
                    required="required"
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$"
                    title="Le mot de passe doit comporter au moins 8 caractères, dont une lettre minuscule, une lettre majuscule, un chiffre et un caractère spécial"
                    value={formValue.plainPassword}
                    onChange={handleChange}
                    minLength="8"
                    maxLength="255"
                  />
                </Form.Group>
                <Form.Group controlId="formPasswordVerif">
                  <Form.Control
                    type="password"
                    placeholder="Confirmer le mot de passe"
                    required="required"
                    pattern={formValue.plainPassword}
                    title="Les mots de passe ne correspondent pas"
                    maxLength="255"
                  />
                </Form.Group>
              </div>
              <LoadingButton className={style.click} variant="primary" type="submit">
                S&apos;inscrire
              </LoadingButton>
            </Form>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}

export default Registration;
