import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import {
  Col, Form, Row, Button,
} from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Decouvrir from '../assets/images/decouvrir.jpg';
import Objectifs from '../assets/images/objectifs.jpg';
import Progresser from '../assets/images/progresser.png';
import Cards from '../components/Home/Cards';
import ModalError from '../components/Home/ModalError';
import NavbarHome from '../components/Home/NavbarHome';
import RootStore from '../RootStore';
import style from './Home.scss';

function Home() {
  const { UserManager } = useContext(RootStore);
  const location = useLocation();
  const navigate = useNavigate();
  const [code, setCode] = useState(null);
  const [message, setMessage] = useState(null);
  const handleChange = (e) => {
    setCode(e.target.value);
  };

  useEffect(() => {
    // Si l'objet location contient un state et que le state contient un message
    if (location.state && location.state.error) {
      // Afficher le message dans le modal
      setMessage(location.state.error);
      // Réinitialiser le state pour qu'il ne s'affiche plus après la fermeture du modal
      navigate(null, { state: { error: null } });
    }
  }, [location]);

  return (
    <>
      {UserManager.isConnected() === true ? (
        <NavbarHome />
      ) : null }
      <div className={style.home}>
        <Row className={style.title}>
          <Col xs={8}>
            <h2>Bienvenue dans la WYS APP !</h2>
            <p>
              Un E-Serious Game pour vous permettre de VOUS comprendre et de comprendre les autres !
            </p>
          </Col>
          <Col xs={4} />
        </Row>
        <Row className={`${style.login} text-center`}>
          {UserManager.isConnected() === true ? (
            <>
              <Col xs={12}>
                <h3>Entrez le code de la partie</h3>
              </Col>
              <Col xs={12} className={style.code}>
                <Form.Label style={{ marginRight: '0.5em' }}>Code :</Form.Label>
                <Form.Control type="text" style={{ width: '10%' }} onChange={handleChange} />
                <Button to={`/formulaire/${code}`} className={`btn btn-primary ${style.btnCode}`} style={{ width: 'auto' }} as={Link}>
                  Rejoindre
                </Button>
              </Col>
              <Col>
                {UserManager.accessControl('ROLE_COACH') === true
                  ? (
                    <Button to="/game" as={Link} style={{ width: '10%' }}>
                      Créer une partie
                    </Button>
                  ) : null}
              </Col>
            </>
          ) : (
            <>
              <Col xs={12}>
                <h3>Pour commencer l&apos;aventure :</h3>
              </Col>
              <Col xs={12}>
                <Button to="/login" as={Link}>
                  Se connecter
                </Button>
                <Button to="/register" as={Link}>
                  S&apos;enregistrer
                </Button>
              </Col>
            </>
          )}
        </Row>
        <div className={`${style.infos} text-center`}>
          <Row className="justify-content-center">
            <Col xs={6}>
              <div className={style.infosProjet}>
                <h4>Avant de jouer</h4>
                <p>
                  La création de ce jeu s&apos;est affinée entre 2020 et 2022.
                  Un grand nombre de personnes ont participé à ce projet en le
                  testant, en l&apos;améliorant et en contribuant à son
                  aboutissement.
                </p>
                <p>
                  Aujourd&apos;hui, notre projet est de recueillir vos réponses
                  anonymes pour entraîner une intelligence artificielle dont les
                  résultats aideront les futurs ingénieurs-managers à mieux
                  encadrer leurs équipes.
                </p>
                <p>
                  Nous vous remercions de répondre aux questions le plus
                  sincèrement possible. Nous vous sommes très reconnaissants de
                  contribuer à l&apos;aboutissement de cette Web App&apos;.
                </p>
                <h4>Pour vous aider</h4>
                <p>
                  Si vous avez des questions ou si vous ressentez un besoin de
                  clarification sur la façon de répondre, nous vous encourageons
                  à nous appeler ! Nous sommes là pour vous et nous vous
                  répondrons avec plaisir. Merci pour les feedbacks que vous
                  pourrez nous faire sur votre utilisation du jeu
                  jehane@winyourstar.com.
                </p>
                <p>
                  C&apos;est grâce à votre retour d&apos;expériences que nous
                  pourrons faire progresser l&apos;outil.
                </p>
                <p>Au plaisir de mieux vous connaître !</p>
                <p>
                  Dorothée Crober
                  <br />
                  Grégoire Parmentier
                  <br />
                  Coachs professionnels certifiés – Formateurs en management
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={4}>
              <Cards
                image={Decouvrir}
                texte="Nous pensons que les leaders de demain auront une connaissance
             précise des fonctionnements émotionnels et relationnels, c'est pourquoi
             nous avons créé « Win Your Star » un support ludique de coaching qui
             offre une meilleure connaissance de soi et de sa relation à l'autre."
                title="Se découvrir"
                colorTitle="#DE1E26"
              />
            </Col>
            <Col xs={4}>
              <Cards
                image={Objectifs}
                texte="A travers vos expériences personnelles passées, présentes ou futures,
             ce jeu coopératif pose des questions qui vous aident à explorer vos émotions,
              à clarifier vos besoins et à activer vos ressources vers l'atteinte de votre nouvel objectif."
                title="Atteindre ses objectifs"
                colorTitle="#F9C013"
              />
            </Col>
            <Col xs={4}>
              <Cards
                image={Progresser}
                texte="Chaque partie permet de mieux vous comprendre aux niveaux physique,
             émotionnel et mental et d'anticiper des réactions efficaces face
              aux nouvelles situations que vous rencontrerez."
                title="Progresser"
                colorTitle="#1370B8"
              />
            </Col>
          </Row>
        </div>
      </div>
      {message ? (
        <ModalError
          message={message}
          onHide={() => setMessage(null)}
        />
      ) : null}
    </>
  );
}

export default observer(Home);
