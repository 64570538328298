import { Button, Spinner } from 'react-bootstrap';
import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import AxiosError from '../../model/AxiosError';

function LoadingButton({
  onClick, children, className, type = 'button', style = {}, variant,
}) {
  const axios = useMemo(() => new AxiosError(), []);
  const { loading } = axios;
  return (
    <Button
      type={type}
      onClick={onClick}
      disabled={loading}
      className={className}
      style={style}
      variant={variant}
    >
      {loading ? (
        <Spinner animation="border" size="sm" />
      ) : (
        children
      )}
    </Button>
  );
}

export default observer(LoadingButton);
