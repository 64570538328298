import { observer } from 'mobx-react';
import React from 'react';
import classNames from 'classnames';
import { CircleFill } from 'react-bootstrap-icons';
import style from './Connected.scss';

function Connected({ otherUser }) {
  const connectedButtonClass = classNames('mt-auto text-end ', {
    [style.connected]: otherUser?.connected,
    [style.OtherUser]: true,
  });
  return (
    <div className={style.connect}>
      <CircleFill size="10" className={connectedButtonClass} />
      {' '}
      {otherUser?.name ? otherUser.name : "En attente de l'utilisateur..."}
    </div>
  );
}

export default observer(Connected);
