import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button, Col, Container, Form, Row, Spinner,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from '../../axiosConfig';
import Back from '../../components/Home/Back';
import Loading from '../../components/Loading/Loading';
import PopUp from '../../components/PopUp/PopUp';
import User from '../../model/User';
import DisplayProfil from '../../components/DisplayProfil';
import LoadingButton from '../../components/Loading/LoadingButton';

function CoachProfil() {
  const [user, setUser] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const idUser = useParams('id').id;

  useEffect(() => {
    axios.post(`${APP_ENV.API_EP_URI}/coach/user/${idUser}`)
      .then((response) => {
        const {
          id, lastName, firstName, email, roles, dateDeNaissance, telephone,
        } = response.data;
        setUser(new User(id, lastName, firstName, email, roles, dateDeNaissance, telephone));
      })
      .catch(() => {
      });
  }, []);
  const handleClick = () => {
    setShowPopup(true);
  };
  const onValid = () => {
    user.toggleRoleCoach();
  };

  const isCoach = user?.roles.includes('ROLE_COACH');

  let buttonText;

  if (isCoach) {
    buttonText = 'Rétrograder ce coach';
  } else {
    buttonText = 'Promouvoir en coach';
  }

  return (
    <div>
      <Back link="/user" texte="Liste des utilisateurs" />
      {user === null
        ? <Loading />
        : (
          <DisplayProfil user={user}>
            <LoadingButton
              onClick={handleClick}
            >
              {buttonText}
            </LoadingButton>
            {showPopup && (
            <PopUp
              affichage
              handleClose={() => (setShowPopup(false))}
              onValid={onValid}
            />
            )}
          </DisplayProfil>
        )}

    </div>
  );
}
export default observer(CoachProfil);
