/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import style from './Debriefing.scss';

function Recap({
  title, reponse, satisfaction, aides, totem,
}) {
  return (
    <div className={style.card}>
      <h5 className={style.questionTitle}>{title}</h5>
      <p>
        Réponse :&nbsp;
        {reponse}
        <br />
      </p>
      <p>
        {satisfaction.name}
        {' '}
        {satisfaction.note}
        <br />
      </p>
      <p>
        Aides :&nbsp;
        {aides.length > 0
          ? aides.join(', ') : ''}
        <br />
      </p>
      <p>
        {totem !== undefined
          ? (
            `Totem : ${totem.join(', ')}`) : ''}
        <br />
      </p>
    </div>
  );
}
export default Recap;
