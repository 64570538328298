import React, { useContext, useEffect } from 'react';

import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import RootStore from '../../RootStore';
import DisplayProfil from '../../components/DisplayProfil';
import Back from '../../components/Home/Back';

function UserProfil() {
  const { UserManager } = useContext(RootStore);

  const navigate = useNavigate();

  useEffect(() => {
    UserManager.loadUser().catch(() => navigate('/login'));
  }, []);

  return (
    <>
      <Back link="/" texte="Accueil" />
      {UserManager.user ? (
        <DisplayProfil user={UserManager.user} />
      ) : null}
    </>
  );
}
export default observer(UserProfil);
