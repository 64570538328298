import React from 'react';
import Container from 'react-bootstrap/Container';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import RootStore from './RootStore';
import STORE from './store';

import Home from './scenes/Home';
import Login from './scenes/Login';
import SelectGame from './scenes/coach/SelectGame';
import Game from './scenes/Game';
import style from './App.scss';
import Registration from './scenes/Registration';
import Historique from './scenes/user/Historique';
import HistoriqueDetail from './scenes/user/HistoriqueDetail';
import HistoriqueCoach from './scenes/coach/HistoriqueCoach';
import ListUser from './scenes/coach/ListUser';
import Page404 from './scenes/Error/Page404';
import UserProfil from './scenes/user/UserProfil';
import CoachProfil from './scenes/coach/CoachProfil';

/**
 * Intérêt pédagogique : encapsultation par un gestionnaire d'erreur ; Routage.
 */

function App() {
  return (
    <BrowserRouter>
      <RootStore.Provider value={STORE}>
        <main role="main">
          <Container
            id="container-main"
            className={`${style.app} justify-content-center d-flex flex-column`}
            fluid
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Registration />} />
              <Route path="/game" element={<SelectGame />} />
              <Route path="/formulaire/:id" element={<Game />} />
              <Route path="/historique" element={<Historique />} />
              <Route path="/historique/:id" element={<HistoriqueDetail />} />
              <Route path="/coach/historique" element={<HistoriqueCoach />} />
              <Route path="/user" element={<ListUser />} />
              <Route path="/profile" element={<UserProfil />} />
              <Route path="/user/:id" element={<CoachProfil />} />
              <Route path="*" element={<Page404 />} />
            </Routes>
          </Container>
        </main>
      </RootStore.Provider>
    </BrowserRouter>
  );
}

export default App;
