import React, { useContext } from 'react';
import ReponseField from './FieldReponse/Reponse';
import RootStore from '../../RootStore';

import style from './Reponse.scss';
import Adjectif from './FieldReponse/Adjectif';
import Satisfaction from './FieldReponse/Satisfaction';
import Objectif from './FieldReponse/Objectif';

function Fields() {
  const { PartieManager } = useContext(RootStore);
  const numQues = PartieManager.numQuestion;

  return (
    <div className={style.Reponse}>
      {numQues === 0 ? <Objectif /> : (
        <>
          <ReponseField />
          <Adjectif ressources={PartieManager.currentQuestion.ressources} />
          <Satisfaction type={PartieManager.currentQuestion.satisfaction} />
        </>
      )}
    </div>
  );
}
export default Fields;
