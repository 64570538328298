import { action, runInAction, makeAutoObservable } from 'mobx';
import axios from 'axios';
import io from 'socket.io-client';
import Partie from './Partie';
import Questions from '../assets/json/translation/questions.json';

class PartieManager {
  _numQuestion;

  _partie;

  _socket;

  _connected;

  _otherUser = {};

  _role;

  _partieSave;

  _dataLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get numQuestion() {
    return this._numQuestion;
  }

  set numQuestion(value) {
    this._numQuestion = value;
  }

  get partie() {
    return this._partie;
  }

  get socket() {
    return this._socket;
  }

  set socket(value) {
    this._socket = value;
  }

  get connected() {
    return this._connected;
  }

  set connected(value) {
    this._connected = value;
  }

  get partieSave() {
    return this._partieSave;
  }

  set partieSave(value) {
    this._partieSave = value;
  }

  get otherUser() {
    return this._otherUser;
  }

  set otherUser(value) {
    this._otherUser = value;
  }

  get currentQuestion() {
    return Questions[this._partie.typePartie][this._numQuestion];
  }

  get currentReponse() {
    return this._partie.reponses[this._numQuestion];
  }

  isCoach() {
    return this._role === 'coach';
  }

  isUser() {
    return this._role === 'user';
  }

  // Utiliser une action pour modifier la propriété `_numQuestion`

  initPartie = action((id, typePartie, User, create) => {
    this._partie = new Partie(id, typePartie, new Date());
    this._socket = io.connect(
      (APP_ENV.NGINX_URI),
      { query: `token=${User.token}` },
    );

    this._socket.emit('join_room', { room: id, create });
    this._partieSave = undefined;
    this._connected = new Promise((resolve, reject) => {
      const time = setTimeout(() => {
        this._socket.disconnect();
        reject(new Error('Connexion refusée : impossible de se connecter au serveur.'));
      }, 5000);
      this._socket.on('successful', (data) => {
        runInAction(() => {
          this._role = data.role;
          this._otherUser = data.otherUser;
        });
        clearTimeout(time);
        resolve(data);
      });
      this._socket.on('error', (data) => {
        clearTimeout(time);
        this._socket.disconnect();
        reject(new Error(data));
      });
    });
    this.socketOn();
    return this._partie;
  });

  updateCurrentQues = action((update) => {
    this._numQuestion += update;
    const currentQues = this._numQuestion;
    this._socket.emit('currentQues', {
      currentQues,
    });
  });

  setObjectif = action((objectif) => {
    this._partie.objectif = objectif;
    this._socket.emit('objectif', {
      objectif,
    });
  });

  setDebriefing = action((debriefing) => {
    this._partie.debriefing = debriefing;
    this._socket.emit('debriefing', {
      debriefing,
    });
  });

  setProgress = action((progress) => {
    this._partie.progress = progress;
    this._socket.emit('progress', {
      progress,
    });
  });

  setReponse = action((reponse, numQues = this._numQuestion) => {
    this._partie.reponses[numQues].reponse = reponse;
    this._socket.emit('partie', {
      reponse, nbQuestion: numQues, champ: 'reponse',
    });
  });

  setSatisfaction = action((satisfaction, numQues = this._numQuestion) => {
    this._partie.reponses[numQues].satisfaction = satisfaction;
    this._socket.emit('partie', {
      satisfaction, nbQuestion: numQues, champ: 'satisfaction',
    });
  });

  setListTags = action((listTags, numQues = this._numQuestion) => {
    if (listTags.length > 15) return null;
    this._partie.reponses[numQues].listTags = listTags;
    this._socket.emit('partie', {
      listTags, nbQuestion: numQues, champ: 'Tags',
    });
  });

  setListTotem = action((listTotem, numQues = this._numQuestion) => {
    this._partie.reponses[numQues].listTotem = listTotem;
    this._socket.emit('partie', {
      listTotem, nbQuestion: numQues, champ: 'Totem',
    });
  });

  sendPartie() {
    if (this._dataLoading) return;
    this._dataLoading = true;
    const data = {
      type: this._partie.typePartie,
      reponses: this._partie.responsetoJson(),
      user: this._partie.user,
      coach: this._partie.coach,
      debriefing: this._partie.debriefing,
      progress: this._partie.progress,
      objectif: this._partie.objectif,
    };
    axios.post(`${APP_ENV.API_EP_URI}/coach/partie/create`, JSON.stringify(data)).then((response) => {
      this._socket.emit('partieSave', response.data.id);
      this._dataLoading = false;
    }).catch((error) => {
      console.log(error.response.data);
      this._dataLoading = false;
    });
  }

  socketOn = () => {
    this._socket.on('objectif', (data) => {
      runInAction(() => {
        this._partie.objectif = data.objectif;
      });
    });

    this._socket.on('user', (data) => {
      runInAction(() => {
        const { id } = data;
        if (id) {
          this._partie.user = id;
        }
      });
    });

    this._socket.on('otherUser', (data) => {
      runInAction(() => {
        this._otherUser = { ...this._otherUser, ...data };
      });
    });

    this._socket.on('coach', (data) => {
      runInAction(() => {
        const { id } = data;
        if (id) {
          this._partie.coach = id;
        }
      });
    });

    this._socket.on('currentQues', (data) => {
      runInAction(() => {
        this._numQuestion = data.currentQues;
      });
    });

    this._socket.on('debriefing', (data) => {
      runInAction(() => {
        this._partie.debriefing = data.debriefing;
      });
    });

    this._socket.on('progress', (data) => {
      runInAction(() => {
        this._partie.progress = data.progress;
      });
    });

    this._socket.on('reponse', (data) => {
      runInAction(() => {
        const reponseData = Object.values(data)[0];
        this._partie.reponses[reponseData.nbQuestion].reponse = reponseData.reponse;
      });
    });

    this._socket.on('satisfaction', (data) => {
      runInAction(() => {
        const reponseData = Object.values(data)[0];
        this._partie.reponses[reponseData.nbQuestion].satisfaction = reponseData.satisfaction;
      });
    });

    this._socket.on('Tags', (data) => {
      runInAction(() => {
        const reponseData = Object.values(data)[0];
        this._partie.reponses[reponseData.nbQuestion].listTags = reponseData.listTags;
      });
    });
    this._socket.on('Totem', (data) => {
      runInAction(() => {
        const reponseData = Object.values(data)[0];
        this._partie.reponses[reponseData.nbQuestion].listTotem = reponseData.listTotem;
      });
    });

    this._socket.on('partieSave', (data) => {
      runInAction(() => {
        this._partieSave = data;
      });
    });
  };
}
export default PartieManager;
