import React from 'react';
import PropTypes from 'prop-types';
import { ArrowLeft } from 'react-bootstrap-icons';
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import style from './Back.scss';

function Back({
  link, texte,
}) {
  return (

    <Link to={link} className={`${style.BackButton} link-light `}>
      <ArrowLeft size="40" />
      {texte}
    </Link>

  );
}

Back.propTypes = {
  link: PropTypes.string.isRequired,
  texte: PropTypes.string.isRequired,

};

export default Back;
