import React from 'react';
import { observer } from 'mobx-react';
import Accordion from 'react-bootstrap/Accordion';
import Recap from './Recap';
import style from './Debriefing.scss';

function Debriefing({
  parties, PartieManager, back = null,
  children,
}) {
  const Reponses = parties.reponses;
  const Objectif = parties.objectif;

  return (
    <>
      {back !== null ? back : null}
      <div style={{ textAlign: 'center' }} className={style.Debriefing}>
        <h1 className={style.reviewTitle}>Debriefing</h1>
        <div className={style.savebutton}>
          {children}
        </div>
        <div className={style.review}>
          <div className={style.answerBlock}>
            <div className={style.Reponse}>
              <p>{Objectif}</p>
            </div>
          </div>
          <div className={style.answerBlock}>
            <h3 className={style.titre}>Debriefing</h3>
            <div className={style.Reponse}>
              {PartieManager ? (
                <textarea
                  id="debrief"
                  name="debrief"
                  placeholder="Que retiendrez-vous, de vous, à travers cette expérience ?"
                  onChange={(e) => PartieManager.setDebriefing(e.target.value)}
                  value={parties.debriefing}
                  maxLength={3000}
                />
              )
                : <p>{parties.debriefing}</p>}
            </div>

            <div className={style.Reponse}>
              {PartieManager ? (
                <>
                  <h3 className={style.titre}>Conquête</h3>
                  <textarea
                    id="progress"
                    name="progress"
                    placeholder={parties.typePartie === 1 ? 'Nouvel Objectif' : 'Premier Pas'}
                    onChange={(e) => PartieManager.setProgress(e.target.value)}
                    value={parties.progress}
                    maxLength={3000}
                  />
                </>
              )
                : (
                  <>
                    <h3 className={style.titre}>{parties.type === 'SOLO RETROACTION' ? 'Nouvel Objectif' : 'Premier Pas'}</h3>
                    <p>{parties.progress}</p>
                  </>
                )}
            </div>
          </div>
          <div className={style.answerBlock}>
            <Accordion className={style.accordeon} defaultActiveKey={PartieManager ? null : '0'}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Résumé</Accordion.Header>
                <Accordion.Body>
                  <h3 className={style.titre}>Propulsion</h3>
                  <div className={style.answerFlex}>
                    <Recap title="Motivation" reponse={Reponses[1].reponse} satisfaction={{ name: 'Intensité :', note: Reponses[1].satisfaction }} aides={Reponses[1].listTags} totem={Reponses[1].listTotem} />
                    <Recap title="Qualité" reponse={Reponses[2].reponse} satisfaction={{ name: 'Maîtrise :', note: Reponses[2].satisfaction }} aides={Reponses[2].listTags} totem={Reponses[2].listTotem} />
                    <Recap title="Enjeu" reponse={Reponses[3].reponse} satisfaction={{ name: 'Intensité :', note: Reponses[3].satisfaction }} aides={Reponses[3].listTags} totem={Reponses[3].listTotem} />
                  </div>
                  <div className={style.answerBlock}>
                    <h3 className={style.titre}>Expedition</h3>
                    <div className={style.answerFlex}>
                      <Recap title="Action" reponse={Reponses[4].reponse} satisfaction={{ name: 'Maîtrise :', note: Reponses[4].satisfaction }} aides={Reponses[4].listTags} totem={Reponses[4].listTotem} />
                      <Recap title="Moyen" reponse={Reponses[5].reponse} satisfaction={{ name: 'Maîtrise :', note: Reponses[5].satisfaction }} aides={Reponses[5].listTags} totem={Reponses[5].listTotem} />
                      <Recap title="Indicateur" reponse={Reponses[6].reponse} satisfaction={{ name: 'Intensité :', note: Reponses[6].satisfaction }} aides={Reponses[6].listTags} totem={Reponses[6].listTotem} />
                    </div>
                  </div>
                  <div className={style.answerBlock}>
                    <h3 className={style.titre}>Exploration</h3>
                    <div className={style.answerFlex}>
                      <Recap title="Obstacle" reponse={Reponses[7].reponse} satisfaction={{ name: 'Intensité :', note: Reponses[7].satisfaction }} aides={Reponses[7].listTags} totem={Reponses[7].listTotem} />
                      <Recap title="Danger" reponse={Reponses[8].reponse} satisfaction={{ name: 'Intensité :', note: Reponses[8].satisfaction }} aides={Reponses[8].listTags} totem={Reponses[8].listTotem} />
                      <Recap title="Réaction" reponse={Reponses[9].reponse} satisfaction={{ name: 'Maîtrise :', note: Reponses[9].satisfaction }} aides={Reponses[9].listTags} totem={Reponses[9].listTotem} />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

            </Accordion>

          </div>

        </div>
      </div>
    </>
  );
}

export default observer(Debriefing);
