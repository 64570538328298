import { observer } from 'mobx-react';
import React from 'react';
import {
  Col, Row, Button, Container,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import style from './Page404.scss';
import totem from '../../assets/images/fuse2.svg';

function page404() {
  return (
    <Container className={`d-flex flex-column ${style.home}`}>
      <Row className={`h-100 d-flex justify-content-center ${style.title}`}>
        <Col xs={7} className="my-auto">
          <h2>Erreur 404</h2>
          <p>
            Nous avons rencontré un problème, veuillez retourner sur la page d&apos;accueil !
          </p>
          <Button to="/" as={Link}>
            Retour à la page d&apos; accueil
          </Button>
        </Col>
        <Col xs={5} className="my-auto">

          <img className="d-block mx-auto w-50" src={totem} alt="fuse" />

        </Col>
      </Row>
    </Container>
  );
}
export default observer(page404);
