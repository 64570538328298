import { makeAutoObservable } from 'mobx';

class Reponse {
  _reponse = '';

  _satisfaction = '';

  _listTags = [];

  _listTotem;

  constructor() {
    makeAutoObservable(this);
  }

  get reponse() {
    return this._reponse;
  }

  set reponse(value) {
    this._reponse = value;
  }

  get satisfaction() {
    return this._satisfaction;
  }

  set satisfaction(value) {
    this._satisfaction = value;
  }

  get listTags() {
    return this._listTags;
  }

  set listTags(value) {
    this._listTags = value;
  }

  get listTotem() {
    return this._listTotem;
  }

  set listTotem(value) {
    this._listTotem = value;
  }

  toJson() {
    const {
      reponse, satisfaction, listTags, listTotem,
    } = this;
    return {
      reponse, satisfaction, listTags, listTotem,
    };
  }
}

export default Reponse;
