import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../RootStore';

import style from './Reponse.scss';

function Reponse() {
  const { PartieManager } = useContext(RootStore);
  const { reponse } = PartieManager.currentReponse;

  const onChange = (e) => {
    PartieManager.setReponse(e.target.value);
  };

  return (

    <div className={style.Reponse}>

      <input
        id="answer"
        name="answer"
        placeholder="Ma réponse"
        type="text"
        value={reponse}
        onChange={onChange}
        autoComplete="off"
      />
    </div>

  );
}
export default observer(Reponse);
