import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import Debriefing from '../../components/Game/Debriefing';
import Back from '../../components/Home/Back';
import Loading from '../../components/Loading/Loading';
import RootStore from '../../RootStore';

function HistoriqueDetail() {
  const { id } = useParams();
  const location = useLocation();
  const [partie, setPartie] = useState(null);

  let back = <Back link="/" texte="Accueil" />;
  const localBack = location.state?.back;
  if (localBack) {
    back = <Back link={localBack.link} texte={localBack.text} />;
  }

  const { UserManager } = useContext(RootStore);

  let url;
  if (UserManager.accessControl('ROLE_COACH')) {
    url = `${APP_ENV.API_EP_URI}/coach/history/${id}`;
  } else {
    url = `${APP_ENV.API_EP_URI}/user/history/${id}`;
  }

  useEffect(() => {
    axios.get(url).then((response) => {
      setPartie(response.data);
    });
  }, []);

  return (
    <div>
      {partie ? (
        <Debriefing parties={partie} back={back}>
          <Button href={UserManager.accessControl('ROLE_COACH') ? `${APP_ENV.API_EP_URI}/coach/pdf/${id}` : `${APP_ENV.API_EP_URI}/user/pdf/${id}`}>Télécharger en PDF</Button>
        </Debriefing>
      ) : (
        <Loading back={back} />
      )}
    </div>
  );
}

export default HistoriqueDetail;
