/* eslint-disable no-shadow */
/* eslint-disable no-console */
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Col, Row } from 'react-bootstrap';
import SearchTab from '../../components/List/SearchTab';
import RootStore from '../../RootStore';
import Back from '../../components/Home/Back';
import Loading from '../../components/Loading/Loading';
import CustomPagination from '../../components/List/Pagination';

function Historique() {
  const navigate = useNavigate();
  const columns = {
    Date: { trie: ['date'], size: 7 },
    'Type de partie': { trie: ['type'], size: 10 },
    Objectif: { trie: ['objectif'] },
    Debriefing: { trie: ['debriefing'] },
  };
  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalItems, setTotalItems] = useState(10);

  const totalPages = Math.ceil(totalItems / limit);
  if (totalPages < page && totalPages > 0) {
    setPage(totalPages);
  }

  useEffect(() => {
    axios.get(`${APP_ENV.API_EP_URI}/user/history`)
      .then((response) => {
        const wdata = response.data.map(
          ({
            id,
            date,
            type,
            objectif,
            debriefing,
          }) => ({
            id,
            date: new Date(date),
            type,
            objectif,
            debriefing,
          }),
        );
        setData(wdata);
      });
  }, []);

  const onclickRow = (elt) => {
    navigate(`/historique/${elt}`, { state: { back: { link: '/historique', text: 'Historique' } } });
  };

  return (
    <div>
      <Back link="/" texte="Accueil" />
      {data
        ? (
          <>
            <h1 className="text-center">Liste des parties jouées</h1>
            <Row className="justify-content-center">
              <Col xs={10}>
                <SearchTab
                  col={columns}
                  data={data}
                  keyOptions={{ key: 'id', displayKey: false, onclickRow }}
                  pagination={{ limit, page, onTotal: (total) => setTotalItems(total) }}
                  size={100}
                />
              </Col>
            </Row>
            <Row className="justify-content-center mt-3">
              <Col xs={10}>
                <CustomPagination
                  currentPage={page}
                  totalPages={totalPages}
                  onPageChange={(newPage) => setPage(newPage)}
                />
              </Col>
            </Row>
          </>
        ) : (
          <Loading />
        )}
    </div>
  );
}
export default Historique;
