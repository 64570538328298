import React, { useContext, useMemo } from 'react';
import Alert from 'react-bootstrap/Alert';
import { observer } from 'mobx-react';
import AxiosError from '../../model/AxiosError';

function ErrorForm() {
  const Error = useMemo(() => new AxiosError(), []);

  if (Error.errors === undefined) {
    return null;
  }

  return (
    <Alert variant="danger">
      <Alert.Heading>{Error.errors.message}</Alert.Heading>
      {/* <p> */}
      {/*  {message} */}
      {/* </p> */}
    </Alert>
  );
}

export default observer(ErrorForm);
