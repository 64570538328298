import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';

import { observer } from 'mobx-react';
import Step from './Step';

import style from './Stepbar.scss';
import fleche from '../../assets/images/chevron-droit.png';
import RootStore from '../../RootStore';

function StepBar() {
  const { PartieManager } = useContext(RootStore);
  // useEffect(() => {
  //   PartieManager.setCurrentQuestion(0);
  // }, []);

  const onclickLeft = () => {
    if (PartieManager.numQuestion > 0 && PartieManager.partie.user) {
      PartieManager.updateCurrentQues(-1);
    }
  };

  const onclickRight = () => {
    if (PartieManager.partie.user) {
      PartieManager.updateCurrentQues(1);
    }
  };

  return (
    <Row>
      <Col xs={2} className="d-flex align-items-center justify-content-center m-0">
        {PartieManager.isCoach() === true
          ? (
            <button
              type="button"
              onClick={onclickLeft}
              className={style.btn}
              disabled={!(PartieManager.numQuestion > 0 && PartieManager.partie.user)}
            >
              <img src={fleche} alt="arrowLeft" className={`${style.imgLeft} ${style.imgBtn}`} />
              Question précédente
            </button>
          ) : null}
      </Col>
      <Col xs={8} className="m-0">
        <Step current={PartieManager.numQuestion} />
      </Col>
      <Col xs={2} className="d-flex align-items-center justify-content-center m-0">
        {PartieManager.isCoach() === true
          ? (
            <button
              type="button"
              onClick={onclickRight}
              className={style.btn}
              disabled={!PartieManager.partie.user}
            >
              Question suivante
              <img src={fleche} alt="arrowLeft" className={`${style.imgBtn}`} />
            </button>
          ) : null}

      </Col>
    </Row>
  );
}
export default observer(StepBar);
