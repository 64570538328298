import { makeAutoObservable } from 'mobx';
import Reponse from './Reponse';

class Partie {
  _id;

  _typePartie;

  _datePartie;

  _reponses = [];

  _user;

  _coach;

  _debriefing;

  _progress;

  _objectif;

  constructor(id, typePartie, datePartie) {
    makeAutoObservable(this);
    this._id = id;
    this._typePartie = typePartie;
    this._datePartie = datePartie;

    this._objectif = '';
    this._debriefing = '';
    this._progress = '';

    for (let index = 1; index <= 9; index += 1) {
      this._reponses[index] = new Reponse();
    }
  }

  get id() {
    return this._id;
  }

  get typePartie() {
    return this._typePartie;
  }

  set typePartie(value) {
    this._typePartie = value;
  }

  get datePartie() {
    return this._datePartie;
  }

  get reponses() {
    return this._reponses;
  }

  set reponses(value) {
    this._reponses = value;
  }

  get user() {
    return this._user;
  }

  set user(value) {
    this._user = value;
  }

  get coach() {
    return this._coach;
  }

  set coach(value) {
    this._coach = value;
  }

  set objectif(value) {
    this._objectif = value;
  }

  get objectif() {
    return this._objectif;
  }

  get debriefing() {
    return this._debriefing;
  }

  set debriefing(value) {
    this._debriefing = value;
  }

  get progress() {
    return this._progress;
  }

  set progress(value) {
    this._progress = value;
  }

  responsetoJson() {
    return this._reponses.map((reponse) => (reponse ? reponse.toJson() : null));
  }
}

export default Partie;
