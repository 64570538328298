import React, {
  useEffect, useState,
} from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import style from './SearchTab.scss';

function SearchTab({
  col,
  data,
  search: { searchValue = '', prop } = {},
  pagination: { limit, page = 1, onTotal = () => {} } = {},
  keyOptions: { key, displayKey = true, onclickRow = () => {} } = {},
  size = 50,
}) {
  // Utilisation de hooks pour gérer l'état de plusieurs variables
  const [selected, setSelected] = useState({ col: null, desc: false });
  const [dataFilter, setDataFilter] = useState(data);
  const [dataSort, setDataSort] = useState(data);
  const [dataList, setDataList] = useState(dataFilter);

  // Fonctions pour filtrer les données en fonction de la propriété de recherche
  function filterByProp(wprop) {
    return (search) => (item) => String(item[wprop]).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase()
      .startsWith(search.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase());
  }
  function filterWithoutProp() {
    return (search) => (item) => Object.values(item).some(
      (value) => String(value).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase()
        .startsWith(search.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase()),
    );
  }

  // Utilisation d'un autre hook pour gérer l'état
  // du filtre courant en fonction de la propriété de recherche
  const [filtreCourant, setFiltreCourant] = useState(filterWithoutProp);

  function onclick(titre) {
    const wSelected = { col: titre, desc: false };
    if (selected.col === titre) {
      if (!selected.desc) {
        wSelected.desc = true;
      }
    }
    setSelected(wSelected);
  }

  // Utilisation d'un hook pour mettre à jour le
  // filtre courant en fonction de la propriété de recherche
  useEffect(() => {
    let filtre = filterWithoutProp();
    if (prop !== undefined) {
      filtre = filterByProp(prop);
    }
    setFiltreCourant(() => filtre);
  }, [prop]);

  // Utilisation d'un hook pour filtrer les données
  // en fonction du filtre courant et de la propriété de recherche
  useEffect(() => {
    const wList = data.filter(
      (item) => filtreCourant(searchValue)(item),
    );
    setDataFilter([...wList]);
    onTotal(wList.length);
  }, [data, filtreCourant, searchValue]);

  // Utilisation d'un hook pour trier les
  // données en fonction de la colonne sélectionnée
  useEffect(() => {
    if (selected.col !== null) {
      col[selected.col].trie.forEach((unCritere) => {
        dataFilter.sort((item1, item2) => {
          const value1 = item1[unCritere];
          const value2 = item2[unCritere];
          if (value1 instanceof Date && value2 instanceof Date) {
            // Si les deux valeurs sont de type Date, on les compare
            return value1.getTime() - value2.getTime();
          }
          // Sinon, on utilise la méthode localeCompare pour les trier
          return String(value1).localeCompare(String(value2), undefined, {
            numeric: true,
          });
        });
      });

      if (selected.desc) {
        dataFilter.reverse();
      }
    }
    setDataSort([...dataFilter]);
  }, [dataFilter, selected]);

  // Utilisation d'un autre hook
  // pour paginer les données
  useEffect(() => {
    let wList = dataSort;
    if (limit) {
      const startIndex = (page - 1) * limit; // Index de départ (inclusif)
      const endIndex = startIndex + limit;
      wList = wList.slice(startIndex, endIndex);
    }

    setDataList([...wList]);
  }, [dataSort, limit, page]);

  const remainingSize = (Object.values(col).reduce((cumul, { size = 0 }) => cumul - size, 100));
  function calculLargeurCol(size) {
    if (size !== undefined) {
      return size;
    }
    return (remainingSize / (Object.keys(col).length).toString());
  }

  function getStyle(size) {
    return { width: `${size}%`, maxWidth: `${size / 100}em` };
  }

  const styleTable = { width: `${size}%` };

  return (
    <Table hover className={`${style.searchTab} ${style.tableCustom} table-striped table-sm mx-auto mb-auto`} style={styleTable}>
      <thead>
        <tr>
          {Object.keys(col).map(
            (titre) => (
              <th
                style={getStyle(calculLargeurCol(col[titre].size))}
                scope="col"
                className={selected.col === titre ? style.selected + (selected.desc === true ? ` ${style.desc}` : '') : ''}
                onClick={() => { onclick(titre); }}
                key={titre}
              >
                {titre}
              </th>
            ),
          )}
        </tr>
      </thead>
      <tbody>
        {dataList.map((obj) => {
          const { [key]: _, ...rest } = obj;
          return displayKey ? obj : rest;
        }).map((elt, trIndex) => (
          <tr
            key={key !== undefined ? dataList[trIndex][key] : trIndex}
            onDoubleClick={() => { onclickRow(dataList[trIndex][key]); }}
          >
            {Object.values(elt).map(
              (valueEtu, tdIndex) => (
                <td
                  style={getStyle(calculLargeurCol(Object.values(col)[tdIndex].size))}
                  key={`${Object.keys(elt)[tdIndex]}-${key !== undefined ? dataList[trIndex][key] : trIndex}`}
                >
                  {valueEtu instanceof Date ? valueEtu.toLocaleDateString('fr') : valueEtu}
                </td>
              ),
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

// SearchTab.propTypes = {
//   col: PropTypes.object.isRequired,
//   data: PropTypes.object.isRequired,
//   search: PropTypes.object,
// };

export default SearchTab;
