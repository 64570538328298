import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../RootStore';

import style from './Reponse.scss';

function Objectif() {
  const { PartieManager } = useContext(RootStore);
  const Partie = PartieManager.partie;
  const { objectif } = Partie;

  const onChange = (e) => {
    PartieManager.setObjectif(e.target.value);
  };

  const answerObjectif = {
    1: 'Quel était votre objectif ?',
    2: 'Quel est votre objectif ?',
    3: 'Quel sera votre objectif ?',
  };

  return (

    <div className={style.Reponse}>
      <label htmlFor="answer">{answerObjectif[Partie.typePartie]}</label>
      <input
        id="answer"
        name="answer"
        placeholder="Entrer votre réponse"
        type="text"
        value={objectif}
        onChange={onChange}
        maxLength={255}
      />
    </div>

  );
}
export default observer(Objectif);
