import React from 'react';
import { Steps } from 'antd';
import wystar from '../../assets/images/etoile.png';
import fusee from '../../assets/images/FUSEE_WYS.svg';
import StepIcon from './StepIcon';
import style from './Step.scss';

const { Step } = Steps;

function StepBar({ current }) {
  function icon(nb) {
    if (nb === current) {
      return <img style={{ transform: 'rotate(90deg)', height: '30px' }} src={fusee} alt="illustration" />;
    }
    return <StepIcon number={nb} />;
  }
  return (
    <div className={style.StepBar}>
      <Steps current={current} className={style.Step}>
        <Step
          style={{ height: 40 }}
          title=""
          icon={(
            <div style={{
              width: 40, height: 40, backgroundColor: '#2196f3', borderRadius: '50%',
            }}
            />
)}
        />
        <Step title="" icon={icon(1)} />
        <Step title="" icon={icon(2)} />
        <Step title="" icon={icon(3)} />
        <Step title="" icon={icon(4)} />
        <Step title="" icon={icon(5)} />
        <Step title="" icon={icon(6)} />
        <Step title="" icon={icon(7)} />
        <Step title="" icon={icon(8)} />
        <Step title="" icon={icon(9)} />
        <Step title="" icon={<img style={{ width: '48px' }} src={wystar} alt="illustration" />} />
      </Steps>
    </div>
  );
}
export default StepBar;
