import React, { useEffect, useMemo } from 'react';
import ReactLoading from 'react-loading';
import { observer } from 'mobx-react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import style from './Loading.scss';
import AxiosError from '../../model/AxiosError';

function Loading({ back = null }) {
  const Error = useMemo(() => new AxiosError(), []);
  const navigate = useNavigate();
  useEffect(() => {
    switch (Error.errors?.code) {
      case 401:
        navigate('/login');
        break;
      case 404:
        navigate('*');
        break;
      default:
    }
  }, [Error.errors]);

  if (Error.errors) {
    return (
      <>
        {back}
        <div className={style.loading}>
          <h2 className={style.chargement}>
            Erreur&nbsp;
            {Error.errors.code}
          </h2>
          <p>{Error.errors.message}</p>
        </div>
      </>
    );
  }

  return (
    <>
      {back}
      <div className={style.loading}>
        <h2 className={style.chargement}>chargement...</h2>
        <ReactLoading type="spin" color="white" height={667} width={375} />
      </div>
    </>
  );
}

export default observer(Loading);
