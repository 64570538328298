import React, { useContext } from 'react';

import ressourcesJson from '../../assets/json/cards/ressources.json';
import besoinsJson from '../../assets/json/cards/besoins.json';
import affectsJson from '../../assets/json/cards/affects.json';
import triangle from '../../assets/images/triangle.svg';
import carre from '../../assets/images/carre.svg';
import coeur from '../../assets/images/coeur.svg';

import style from './ressources.scss';
import RootStore from '../../RootStore';

// Composant carte pour les ressources, affects et besoins
function Ressources({ indexRessource = 'ressources' }) {
  const { PartieManager } = useContext(RootStore);
  const { listTags } = PartieManager.currentReponse;

  const cartesJson = {
    ressources: ressourcesJson,
    besoins: besoinsJson,
    affects: affectsJson,
  };

  const index_json_0 = cartesJson[indexRessource][Object.keys(cartesJson[indexRessource])[0]];
  const index_json_1 = cartesJson[indexRessource][Object.keys(cartesJson[indexRessource])[1]];
  const index_json_2 = cartesJson[indexRessource][Object.keys(cartesJson[indexRessource])[2]];

  const buildRenderIndexJson = ({ indexJSON }) => indexJSON.map((unElem, index) => (listTags.includes(unElem) ? (
    <p
      className={style.checked}
      key={index}
      onClick={(evt) => {
        evt.target.classList.toggle('checked');
        PartieManager.setListTags(listTags.filter((tag) => tag !== unElem));
      }}
    >
      {unElem}
    </p>
  ) : (
    <p
      key={index}
      onClick={(evt) => {
        evt.target.classList.toggle('checked');
        PartieManager.setListTags([...listTags, unElem]);
      }}
    >
      {unElem}
    </p>
  )));

  return (
    <div className={style.wysCard}>
      <div className={style.wysCard__categorie}>
        <img style={{ height: '30px', margin: '20px' }} src={carre} alt="carre" />
        <div className={style.wysCard__subTitle}>

          <span className={style.wysCard__subTitleRed}>
            {Object.keys(cartesJson[indexRessource])[0]}
          </span>

        </div>
        <div className={`${style.wysCard__items} ${style.wysCard__itemsRed}`}>
          {buildRenderIndexJson({ indexJSON: index_json_0 })}
        </div>
      </div>
      <div className={style.wysCard__categorie}>
        <img style={{ height: '30px', margin: '20px' }} src={coeur} alt="coeur" />
        <div className={style.wysCard__subTitle}>

          <span className={style.wysCard__subTitleYellow}>
            {Object.keys(cartesJson[indexRessource])[1]}
          </span>
        </div>
        <div className={`${style.wysCard__items} ${style.wysCard__itemsYellow}`}>
          {buildRenderIndexJson({ indexJSON: index_json_1 })}
        </div>
      </div>
      <div className={style.wysCard__categorie}>
        <img style={{ height: '30px', margin: '20px' }} src={triangle} alt="triangle" />
        <div className={style.wysCard__subTitle}>

          <span className={style.wysCard__subTitleBlue}>
            {Object.keys(cartesJson[indexRessource])[2]}
          </span>
        </div>
        <div className={`${style.wysCard__items} ${style.wysCard__itemsBlue}`}>
          {buildRenderIndexJson({ indexJSON: index_json_2 })}
        </div>
      </div>
    </div>
  );
}

export default Ressources;
