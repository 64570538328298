import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

import style from './Pagination.scss';

function CustomPagination({
  currentPage, totalPages, onPageChange, maxPages = 5,
}) {
  const items = [];
  let startPage = 1;
  let endPage = totalPages;

  // Determine the start and end page numbers to display
  if (totalPages > maxPages) {
    const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
    const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;

    if (currentPage <= maxPagesBeforeCurrentPage) {
      endPage = maxPages;
    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
      startPage = totalPages - maxPages + 1;
    } else {
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
    }
  }

  // Create an array of page numbers to display in the pagination
  for (let number = startPage; number <= endPage; number += 1) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => onPageChange(number)}
      >
        {number}
      </Pagination.Item>,
    );
  }

  // Add dots at the start and end of the pagination if needed
  if (totalPages > maxPages && endPage < totalPages) {
    items.push(
      <Pagination.Ellipsis key="ellipsis-end" disabled />,
    );
    items.push(
      <Pagination.Item
        key={totalPages}
        onClick={() => onPageChange(totalPages)}
      >
        {totalPages}
      </Pagination.Item>,
    );
  }

  if (startPage > 1) {
    items.unshift(
      <Pagination.Ellipsis key="ellipsis-start" disabled />,
    );
    items.unshift(
      <Pagination.Item
        key={1}
        onClick={() => onPageChange(1)}
      >
        {1}
      </Pagination.Item>,
    );
  }

  return (
    <Pagination className={style.Pagination}>
      <Pagination.Prev
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}
      />
      {items}
      <Pagination.Next
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1)}
      />
    </Pagination>
  );
}

export default CustomPagination;
