import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import illu1 from '../../assets/images/illu1.png';
import illu2 from '../../assets/images/illu2.png';
import illu3 from '../../assets/images/illu3.png';

import Back from '../../components/Home/Back';
import RootStore from '../../RootStore';

import Cards from '../../components/SelectGame/Cards';
import style from './SelectGame.scss';

function SelectGame() {
  const { UserManager } = useContext(RootStore);
  const navigate = useNavigate();
  const [selected, setSelected] = useState(null);
  // eslint-disable-next-line no-console
  // console.log(UserManager.accessControl('ROLE_COACH'));

  useEffect(() => {
    if (!UserManager.accessControl('ROLE_COACH')) {
      if (UserManager.isConnected()) {
        navigate('/');
      } else {
        navigate('/login');
      }
    }
  }, []);

  const onClick = () => {
    if (selected !== null) {
      // génere code aléatoire pour la room
      const r = (Math.random() + 1).toString(36).substring(7);
      const room = `${selected}${r}`;
      navigate(`/formulaire/${room}`, { state: { create: room } });
    }
  };

  return (
    <>
      <Back link="/" texte="Accueil" />
      <Row className={`justify-content-center ${style.SelectGame} w-100 my-auto`}>
        <Col xs={4} className="flex-fill">
          <Cards
            isSelected={selected === 1}
            image={illu3}
            texte="Il s’agit ici de se remémorer un grand moment de fierté.
            Cette première étape vous permet de découvrir la part du mental qui a
            compté dans la réalisation d’un événement ou d’une performance qui vous a rendu heureux."
            title="Rétroaction"
            onclick={() => setSelected(1)}
          />
        </Col>
        <Col xs={4} className="flex-fill">
          <Cards
            isSelected={selected === 2}
            image={illu2}
            texte="Il s’agit ici de se projeter vers un objectif réel ou non et d’envisager sa progression.
             Cette partie permet de tester l’envie d’entreprendre une nouvelle démarche de progression,
              ou simplement de s’amuser."
            title="Immersion"
            onclick={() => setSelected(2)}
          />
        </Col>
        <Col xs={4} className="flex-fill">
          <Cards
            isSelected={selected === 3}
            image={illu1}
            texte="Il s’agit ici de définir un objectif personnel (si 1 joueur) ou commun (si plusieurs joueurs).
            Cette partie agit comme un révélateur d’engagement et consiste, pour le ou les joueurs, à construire
             un plan d’action concret."

            title="Conquête"
            onclick={() => setSelected(3)}
          />
        </Col>
        <Col className="text-center">
          <button
            type="button"
            className={style.btnValider}
            onClick={onClick}
            disabled={selected === null}
          >

            Commencer l&apos;aventure !
          </button>
        </Col>
      </Row>
    </>
  );
}

export default SelectGame;
