import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { Radio } from 'antd';
import classNames from 'classnames';
import RootStore from '../../../RootStore';
import style from './Satisfaction.scss';

function Satisfaction({ type }) {
  const { PartieManager } = useContext(RootStore);
  const { satisfaction } = PartieManager.currentReponse;
  const classnames = classNames(style.radioGroup, { [style.radioGroupRed]: type === 'I' });
  const onChange = (e) => {
    PartieManager.setSatisfaction(e.target.value);
  };

  return (

    <div className={style.Satisfaction}>
      <p>{type === 'M' ? 'Maîtrise' : 'Intensité'}</p>
      <Radio.Group value={satisfaction} onChange={onChange} buttonStyle="solid" className={classnames}>
        <Radio.Button value="---">---</Radio.Button>
        <Radio.Button value="--">--</Radio.Button>
        <Radio.Button value="-">-</Radio.Button>
        <Radio.Button value="=">=</Radio.Button>
        <Radio.Button value="+">+</Radio.Button>
        <Radio.Button value="++">++</Radio.Button>
        <Radio.Button value="+++">+++</Radio.Button>
      </Radio.Group>
    </div>

  );
}
export default observer(Satisfaction);
