import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Button, Image, Row,
} from 'react-bootstrap';

import style from './Cards.scss';

function Cards({
  title, image, texte, colorTitle,
}) {
  const titleStyle = {
    backgroundColor: colorTitle,
  };

  return (
    <div className={style.cards}>
      <h4 style={titleStyle}>{title}</h4>
      <Image src={image} alt="cards illustration" fluid />
      <p>{texte}</p>
    </div>
  );
}

Cards.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  texte: PropTypes.string.isRequired,
  colorTitle: PropTypes.string,
};

Cards.defaultProps = {
  colorTitle: 'red',
};

export default Cards;
