/* eslint-disable no-shadow */
/* eslint-disable no-console */
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Back from '../../components/Home/Back';
import SearchTab from '../../components/List/SearchTab';
import Loading from '../../components/Loading/Loading';
import RootStore from '../../RootStore';

function ListUser() {
  const columns = {
    Nom: { trie: ['nom'] },
    Prenom: { trie: ['prenom'] },
    Email: { trie: ['email'] },
    Roles: { trie: ['roles'] },
  };
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  function getRole(tabRole) {
    if (tabRole.includes('ROLE_COACH')) {
      return 'Coach';
    }
    return 'Utilisateur';
  }

  useEffect(() => {
    axios.post(`${APP_ENV.API_EP_URI}/coach/user`)
      .then((response) => {
        const wdata = response.data.map(({
          id, lastName: nom, firstName: prenom, email, roles,
        }) => ({
          id,
          nom,
          prenom,
          email,
          roles: getRole(roles),
        }));
        setData(wdata);
      });
  }, []);

  const handleRowClick = (row) => {
    navigate(`/user/${row}`);
  };

  return (
    <div>
      <Back link="/" texte="Accueil" />
      {data ? (
        <>
          <h1 className="text-center">Liste des utilisateurs</h1>
          <SearchTab col={columns} data={data} keyOptions={{ key: 'id', displayKey: false, onclickRow: handleRowClick }} />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
}
export default ListUser;
