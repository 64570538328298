import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';
import {
  Button, Col, Container, Row,
} from 'react-bootstrap';
import PopUp from './PopUp/PopUp';
import RootStore from '../RootStore';
import style from './DisplayProfil.scss';

function DisplayProfil({ user, children }) {
  const { UserManager } = useContext(RootStore);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState();

  const handleClick = () => {
    setShowPopup(true);
  };

  return (
    <Container fluid className={` ${style.Login} d-flex flex-column`}>
      <Row className="justify-content-center w-100  h-100">
        <Col xs="4" className="text-center h-auto my-auto mx-auto ">
          <Col className={`text-center h-auto my-auto mx-auto ${style.From}`}>
            <div className={style.field}>
              <h2>Profil</h2>
              <Row className={style.info}>
                <p className={style.label}>
                  Nom :
                  {' '}
                  {user.nom}
                  {' '}
                </p>
              </Row>
              <Row className={style.info}>
                <p className={style.label}>
                  Prénom :
                  {' '}
                  {user.prenom}
                  {' '}
                </p>
              </Row>
              <Row className={style.info}>
                <p className={style.label}>
                  Mail :
                  {' '}
                  {user.email}
                  {' '}
                </p>
              </Row>
              <Row className={style.info}>
                <p className={style.label}>
                  Role :
                  {' '}
                  {user.getLibelleRole()}
                  {' '}
                </p>
              </Row>
              {children}
            </div>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}
export default observer(DisplayProfil);
