import UserManager from './model/UserManager';
import PartieManager from './model/PartieManager';
import AxiosError from './model/AxiosError';

const STORE = {
  UserManager: new UserManager(),
  PartieManager: new PartieManager(),
  // AxiosError: new AxiosError(),
};

export default STORE;
